import { ref } from 'vue'
export default function SchoolsOnboarding(http, constant) {
    const loading = ref(false)
    const response = ref([])

    async function submitOnboarding(formData) {
        try {
            loading.value = true

            const f = new FormData(formData);
            const response = await http.post(constant.SCHOOLS_ONBOARDING, f)
            const { data, status } = response
            response.value = data
        } catch (err) { console.log(err) }
        loading.value = false
    }
    
    async function updateSchool(formData, id)
    {
        try {
            loading.value = true

            const f = new FormData(formData);
            f.append('id', id)
            const response = await http.post(constant.SCHOOLS_EDIT, f)
            const { data, status } = response
            response.value = data
        } catch (err) { console.log(err) }
        loading.value = false
    }

    return {
        loading,
        response,
        submitOnboarding,
        updateSchool
    }
}